import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns'; 
import 'react-datepicker/dist/react-datepicker.css';
import { Container } from 'react-bootstrap';
import PointEditForm from './PointEditForm';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';

const AdminReport = () => {
    const [rankingType, setRankingType] = useState('weekly'); // State for ranking type
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedLevel, setSelectedLevel] = useState('');
    const [pointsData, setPointsData] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [showPointEditForm, setShowPointEditForm] = useState(false);

    useEffect(() => {
        // Reset pointsData when rankingType changes
        setPointsData([]);
        
        if (rankingType === 'weekly' && selectedLevel) {
            fetchPointsData();
        } else if (rankingType === 'overall' && selectedLevel) {
            fetchOverallPointsData();
        }
    }, [rankingType, selectedDate, selectedLevel]);

    const formatDate = (date) => {
        return format(date, 'dd-MM-yyyy');
    };

    const fetchPointsData = async () => {
        try {
            const formattedDate = formatDate(selectedDate);
            const baseUrl = process.env.REACT_APP_SERVER_URL;
            const endpoint = `${baseUrl}/api/spoints/weekly/${formattedDate}/${selectedLevel}`;
            const response = await axios.get(endpoint);
            setPointsData(response.data);
            console.log(response.data);
        } catch (error) {
            console.error('Error fetching points data:', error);
        }
    };

    const fetchOverallPointsData = async () => {
        try {
            const baseUrl = process.env.REACT_APP_SERVER_URL;
            const endpoint = `${baseUrl}/api/spoints/overall/${selectedLevel}`;
            const response = await axios.get(endpoint);
            setPointsData(response.data);
            console.log(response.data);
        } catch (error) {
            console.error('Error fetching overall points data:', error);
        }
    };

    const handleEdit = (data) => {
        setSelectedStudent(data);
        setShowPointEditForm(true);
    };

    const handleCloseEditForm = () => {
        setShowPointEditForm(false);
    };

    const sortedPointsData = [...pointsData].sort((a, b) => b.totalPoints - a.totalPoints);

    return (
        <div>
            <h2>Student Report</h2>
            <label>Select Ranking Type:</label>
            <select value={rankingType} onChange={e => setRankingType(e.target.value)}>
                <option value="weekly">Weekly Ranking</option>
                <option value="overall">Overall Ranking</option>
            </select>

            {rankingType === 'weekly' && (
                <>
                    <label>Select Date:</label>
                    <DatePicker selected={selectedDate} onChange={date => setSelectedDate(date)} />
                </>
            )}
            <label>Select Level:</label>
            <select value={selectedLevel} onChange={e => setSelectedLevel(e.target.value)}>
                {[...Array(10).keys()].map(i => (
                    <option key={i + 1} value={i + 1}>{i + 1}</option>
                ))}
            </select>

            <Container fluid>
                <table className="table">
                    <thead>
                        <tr>
                            {rankingType === 'weekly' && <th>Weekly Rank</th>}
                            {rankingType === 'overall' && <th>Overall Rank</th>}
                            <th>Name</th>
                            {rankingType === 'weekly' && (
                                <>
                                    <th>Batch</th>
                                    <th>Level</th>
                                    <th>App Practice</th>
                                    <th>Classwork</th>
                                    <th>Homework</th>
                                    <th>Oral</th>
                                    <th>Mental</th>
                                    <th>Worksheet</th>
                                    <th>Activity</th>
                                    <th>Punctuality</th>
                                    <th>Dress</th>
                                    <th>Total Points</th>
                                </>
                            )}
                            {rankingType === 'overall' && (
                                <>
                                    <th>Level</th>
                                    <th>Batch</th>
                                    <th>Overall Points</th>
                                </>
                            )}
                            {rankingType === 'weekly' && <th>Actions</th>}
                        </tr>
                    </thead>
                    <tbody>
                    {sortedPointsData.map((data, index) => (
                        <tr key={data.studentId || data.id}>
                        {rankingType === 'weekly' && <td>{index + 1}</td>}
                        {rankingType === 'overall' && <td>{data.overallRank}</td>}
                        {/* Handle name, batch, and level differently based on rankingType */}
                        <td>{rankingType === 'weekly' ? data?.Student?.name : data?.name}</td>
                        {rankingType === 'weekly' && (
                            <>
                            <td>{data?.Student?.batch}</td>
                            <td>{data?.level}</td>
                            <td>{data?.appPractice}</td>
                            <td>{data?.classwork}</td>
                            <td>{data?.homework}</td>
                            <td>{data?.oral}</td>
                            <td>{data?.mental}</td>
                            <td>{data?.worksheet}</td>
                            <td>{data?.activity}</td>
                            <td>{data?.punctuality}</td>
                            <td>{data?.dress}</td>
                            <td>{data?.totalPoints}</td>
                            </>
                        )}
                        {rankingType === 'overall' && (
                            <>
                            <td>{data?.level}</td>
                            <td>{data?.batch}</td>
                            <td>{data?.overallPoints}</td>
                            </>
                        )}
                        {rankingType === 'weekly' && (
                            <td>
                            <Button onClick={() => handleEdit(data)} variant="contained" startIcon={<EditIcon />}>Edit</Button>
                            </td>
                        )}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </Container>

            {/* Render EditForm component */}
            {showPointEditForm && (
                <PointEditForm
                    student={selectedStudent}
                    onClose={handleCloseEditForm}
                    fetchPointsData={fetchPointsData}
                />
            )}
        </div>
    );
};

export default AdminReport;
