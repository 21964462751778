import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Select, MenuItem } from '@mui/material';
import DatePicker from 'react-datepicker';
import BackupRoundedIcon from '@mui/icons-material/BackupRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import { format } from 'date-fns';
import './AssignPoints.css';

const AssignPoints = () => {
  const [students, setStudents] = useState([]);
  const [pointsData, setPointsData] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedBatch, setSelectedBatch] = useState('');
  const [newEntries, setNewEntries] = useState({}); // Track new points

  // Fetch students and points after both batch and date are selected
  useEffect(() => {
    if (selectedBatch && selectedDate) {
      fetchStudentsAndPoints();
    }
  }, [selectedBatch, selectedDate]);

  const fetchStudentsAndPoints = async () => {
    try {
      const baseUrl = process.env.REACT_APP_SERVER_URL;
      const formattedDate = format(selectedDate, 'dd-MM-yyyy');
      const studentEndpoint = `${baseUrl}/api/students/batch/${selectedBatch}`;
      const pointsEndpoint = `${baseUrl}/api/spoints/${formattedDate}/${selectedBatch}`;
      
      const [studentsResponse, pointsResponse] = await Promise.all([
        axios.get(studentEndpoint),
        axios.get(pointsEndpoint)
      ]);
      console.log('Students Response:', studentsResponse.data); // Debug log

      setStudents(studentsResponse.data);
      
      const fetchedPointsData = {};
      pointsResponse.data.forEach(point => {
        fetchedPointsData[point.studentId] = point;
      });

      setPointsData(fetchedPointsData);
      setNewEntries({}); // Clear new entries when fetching new data
    } catch (error) {
      console.error('Error fetching students or points data:', error);
    }
  };

  // Handle changes to points input fields
  const handlePointsChange = (studentId, criterion, value) => {
    const updatedNewEntries = { ...newEntries };
    if (!updatedNewEntries[studentId]) {
      updatedNewEntries[studentId] = {};
    }
    updatedNewEntries[studentId][criterion] = value === '' ? 0 : parseInt(value);
    setNewEntries(updatedNewEntries);
  };

  const handleDeleteEntry = async (studentId) => {
    try {
      // Format the selected date as 'YYYY-MM-DD' (as expected by the backend)
      const formattedDate = format(selectedDate, 'dd-MM-yyyy');
      const baseUrl = process.env.REACT_APP_SERVER_URL;
      
      // Make DELETE request to delete the points entry
      await axios.delete(`${baseUrl}/api/spoints/${formattedDate}/${studentId}`);
      
      // Re-fetch students and points after deletion
      fetchStudentsAndPoints();
    } catch (error) {
      console.error('Error deleting points entry:', error);
    }
  };

  const handleAssignPoints = async () => {
    try {
      const formattedDate = format(selectedDate, 'dd/MM/yyyy');
  
      // Map over newEntries to prepare the data payload
      const dataToSend = Object.entries(newEntries).map(([id, points]) => {
        const studentId = parseInt(id); // Parse id to ensure it's a number
        const student = students.find(student => student.id === studentId); // Find the student from the students array
  
        if (!student) {
          console.warn(`Student not found for ID: ${studentId}`);
          return null; // Skip this entry if the student is not found
        }
  
        const totalPoints = calculateTotalPoints(pointsData[studentId], points);
  
        return {
          date: formattedDate,
          studentId,
          level: student.level,
          ...points,
          totalPoints,
        };
      }).filter(Boolean); // Remove null entries
  
      console.log('Data to send:', dataToSend);
  
      const baseUrl = process.env.REACT_APP_SERVER_URL;
      await axios.post(`${baseUrl}/api/spoints`, dataToSend);
  
      alert('Points assigned successfully!');
      setNewEntries({});
      await fetchStudentsAndPoints();
    } catch (error) {
      console.error('Error assigning points:', error);
      alert('An error occurred while assigning points.');
    }
  };
  
  

  // const handleAssignPoints = async () => {
  //   try {
  //     const formattedDate = format(selectedDate, 'dd/MM/yyyy');
  //     console.log('Students Array:', students); // Debug log
  //     console.log('New Entries:', newEntries); // Debug log
  
  //     // Map over newEntries to prepare the data payload
  //     const dataToSend = Object.entries(newEntries).map(([studentId, points]) => {
  //       const student = students.find(student => student.id === studentId); // Find the student from the students array
  //       console.log('student', student);
  //       const totalPoints = calculateTotalPoints(pointsData[studentId], points); // Calculate total points
  
  //       return {
  //         date: formattedDate,
  //         studentId,
  //         level: student?.level,
  //         ...points,
  //         totalPoints // Include total points in the payload
  //       };
  //     });

  //     console.log('data to send', dataToSend);
  
  //     const baseUrl = process.env.REACT_APP_SERVER_URL;
  //     await axios.post(`${baseUrl}/api/spoints`, dataToSend);
  
  //     alert('Points assigned successfully!');
  //     setNewEntries({}); // Clear new entries
  //     await fetchStudentsAndPoints(); // Refresh data
  //   } catch (error) {
  //     console.error('Error assigning points:', error);
  //     alert('An error occurred while assigning points.');
  //   }
  // };
  

  // Helper function to calculate total points safely
  const calculateTotalPoints = (fetchedPoints = {}, newPoints = {}) => {
    return (
      (fetchedPoints?.totalPoints || 0) +
      Object.values(newPoints || {}).reduce((sum, val) => sum + (val || 0), 0)
    );
  };

  return (
    <Container>
      <h2>Assign Points</h2>
      <div className="select-container">
        <div className="date-picker">
          <DatePicker 
            selected={selectedDate} 
            onChange={date => setSelectedDate(date)} 
            placeholderText="Select Date" 
          />
        </div>
        <div>
          <Select 
            value={selectedBatch} 
            onChange={(e) => setSelectedBatch(e.target.value)} 
            displayEmpty
          >
            <MenuItem value="" disabled>Select Batch</MenuItem>
            <MenuItem value="A1">Batch A1</MenuItem>
            <MenuItem value="A2">Batch A2</MenuItem>
            <MenuItem value="A3">Batch A3</MenuItem>
            <MenuItem value="B1">Batch B1</MenuItem>
            <MenuItem value="B2">Batch B2</MenuItem>
            <MenuItem value="B3">Batch B3</MenuItem>
            <MenuItem value="C1">Batch C1</MenuItem>
            <MenuItem value="C2">Batch C2</MenuItem>
            <MenuItem value="C3">Batch C3</MenuItem>
          </Select>
        </div>
      </div>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Batch</TableCell>
              <TableCell>Level</TableCell>
              {['App Practice', 'Classwork', 'Homework', 'Oral', 'Mental', 'Worksheet', 'Activity', 'Punctuality', 'Dress'].map((header, index) => (
                <TableCell key={index}>{header}</TableCell>
              ))}
              <TableCell>Total Points</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {students.map(student => (
              <TableRow key={student.id}>
                <TableCell>{student.name}</TableCell>
                <TableCell>{student.batch}</TableCell>
                <TableCell>{student.level}</TableCell>
                {['appPractice', 'classwork', 'homework', 'oral', 'mental', 'worksheet', 'activity', 'punctuality', 'dress'].map((criterion, index) => (
  <TableCell key={index}>
    <TextField
      className="input-field"
      type="text"  // Changed to number type to allow numeric entries
      value={newEntries[student.id]?.[criterion] !== undefined 
                ? newEntries[student.id][criterion] 
                : (pointsData[student.id]?.[criterion] === 0 ? '' : pointsData[student.id]?.[criterion]) || '' }
      onChange={e => handlePointsChange(student.id, criterion, e.target.value)}
      disabled={pointsData[student.id]?.[criterion] > 0}  // Disable fields with existing data
      
    />
  </TableCell>
))}

                <TableCell>
                  {calculateTotalPoints(pointsData[student.id], newEntries[student.id])}
                </TableCell>

                <TableCell>
                  <Button onClick={() => handleDeleteEntry(student.id)} color="error" variant="contained" startIcon={<DeleteIcon />}>
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Button
        variant="contained"
        color="success"
        endIcon={<BackupRoundedIcon />}
        onClick={handleAssignPoints}>
        Submit
      </Button>
    </Container>
  );
};

export default AssignPoints;
